import React from 'react'

import Layout from '../components/layout'

const AboutPage = () => (
  <Layout>
    <h1>About</h1>
  </Layout>
)

export default AboutPage
